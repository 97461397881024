import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState, useMemo } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Input,
  CardTitle,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import APIS from "../../apis"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const UserProfile = props => {
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  const [nom, setNom] = useState("")
  const [nomAr, setNomAr] = useState("")
  const [prenom, setPrenom] = useState("")
  const [prenomAr, setPrenomAr] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [prenomPereAr, setPrenomPereAr] = useState("")
  const [gender, setGender] = useState(1)
  const [date, setDate] = useState("")
  const [gouvernoratNaiss, setGouvernoratNaiss] = useState("")
  const [gouvernoratNaissAr, setGouvernoratNaissAr] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [numero, setNumero] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [file, setFile] = useState(null)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [gouvernorat, setGouvernorat] = useState([])
  const [delegation, setDelegation] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState([])
  const [mention, setMention] = useState([])
  const [session, setSession] = useState([])
  const [payObtention, setPayObtention] = useState("")
  const [premiereIns, setPremiereIns] = useState("")
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [verifPassword, setVerifPassword] = useState("")
  const [errPass, setErrPass] = useState(1)

  const options = useMemo(() => countryList().getData(), [])

  useEffect(async () => {
    const resC = await APIS.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    const res = await APIS.post("etudiant/getById", {
      id: etudiant_id,
    }).then(res => {
      setNom(res.data.Etudiant.nom)
      setNomAr(res.data.Etudiant.nom_ar)
      setPrenom(res.data.Etudiant.prenom)
      setPrenomAr(res.data.Etudiant.prenom_ar)
      setPrenomPere(res.data.Etudiant.prenonPere_fr)
      setPrenomPereAr(res.data.Etudiant.prenonPere_ar)
      setGender(parseInt(res.data.Etudiant.sexe))
      setDate(new Date(res.data.Etudiant.dateNaissance))
      setGouvernoratNaiss(res.data.Etudiant.gouvernoratNaissance_fr)
      setGouvernoratNaissAr(res.data.Etudiant.gouvernoratNaissance_ar)
      setSelectNationnalite(res.data.Etudiant.nationalité)
      setNumero(res.data.Etudiant.cin)
      setEtatCivil(parseInt(res.data.Etudiant.etatCivil))
      setRue(res.data.Etudiant.rue)
      setCodePostal(res.data.Etudiant.codePostal)
      setGouvernorat(res.data.Etudiant.gouvernorat)
      setDelegation(res.data.Etudiant.délégation)
      setTel(res.data.Etudiant.téléphone)
      setEmail(res.data.Etudiant.email)
      setSection(res.data.Etudiant.section)
      setMention(res.data.Etudiant.mention)
      setSession(res.data.Etudiant.session)
      setAnneeBac(res.data.Etudiant.anneBac)
      setPayObtention(res.data.Etudiant.paysObtention)
      setPremiereIns(res.data.Etudiant.premereInscription)
      setSelectNiveau(res.data.Etudiant.niveau)
      setSelectCycle(res.data.Etudiant.cycle)
      setSelectSpecialite(res.data.Etudiant.specialite)
      setLoading(true)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    if (cycle_id == 1) {
      const res = await APIS.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await APIS.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpecialite = async event => {
    var niveau_id = event.value
    setSelectNiveau(event)
    setSelectSpecialite("")
    const res = await APIS.post("specialite/get_by_niveaux_cycle", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.Specialites)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const passVerif = event => {
    var password = event
    const res = APIS.post("password_verification_etd", {
      etudiant_id: etudiant_id,
      password: password,
    }).then(res => {
      var etat = res.data.Etudiant.etat
      if (etat == 0) {
        setErrPass(0)
      } else {
        setErrPass(1)
      }
    })
  }

  const edit = async () => {
    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (password == "") {
      var pass = 0
    } else {
      var pass = password
    }
    var nationalite_id = selectNationnalite.label || 0
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    if (nom == "") {
      toast.error("⛔ Nom  obligatoire !! ", {
        containerId: "A",
      })
    } else if (prenom == "") {
      toast.error("⛔ Prénom obligatoire !! ", {
        containerId: "A",
      })
    } else if (nomAr == "") {
      toast.error("⛔ Nom arabe obligatoire !!", {
        containerId: "A",
      })
    } else if (prenomAr == "") {
      toast.error("⛔ Prénom arabe obligatoire !!", {
        containerId: "A",
      })
    } else if (prenomPere == "") {
      toast.error("⛔ Nom pére obligatoire !!", {
        containerId: "A",
      })
    } else if (prenomPereAr == "") {
      toast.error("⛔ Nom pére arabe obligatoire !!", {
        containerId: "A",
      })
    } else if (numero == "") {
      toast.error("⛔ Identifiant obligatoire !!", {
        containerId: "A",
      })
    } else if (date == "") {
      toast.error("⛔ Date de naissance obligatoire !!", {
        containerId: "A",
      })
    } else if (tel == "") {
      toast.error("⛔ Téléphone obligatoire !!", {
        containerId: "A",
      })
    } else if (!email.match(isValidEmail)) {
      toast.error("⛔ Email obligatoire !!", {
        containerId: "A",
      })
    } else if (selectCycle == "") {
      toast.error("⛔ Cycle obligatoire !!", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Niveau obligatoire !!", {
        containerId: "A",
      })
    } else if (selectSpecialite != "") {
      if (password == verifPassword) {
        const res = await APIS.post("etudiant/edit", {
          id: etudiant_id,
          nom: nom,
          nom_ar: nomAr,
          prenom: prenom,
          prenom_ar: prenomAr,
          prenonPere: prenomPere,
          prenonPere_ar: prenomPereAr,
          sexe: gender,
          dateNaissance: convertDate,
          gouvernoratNaissance: gouvernoratNaiss,
          gouvernoratNaissance_ar: gouvernoratNaissAr,
          nationalite: nationalite_id,
          etatCivil: etatCivil,
          fichier: pathFile,
          rue: rue,
          email: email,
          téléphone: tel,
          codePostal: codePostal,
          gouvernorat: gouvernorat,
          delegation: delegation,
          anneBac: anneeBac,
          section: section,
          mention: mention,
          session_etd: session,
          paysObtention: payObtention,
          premereInscription: premiereIns,
          cycle_id: selectCycle.value,
          niveau_id: selectNiveau.value,
          specialite_id: selectSpecialite.value,
        }).then(res => {
          if (res.data.status === 200) {
            const res = APIS.post("update_mdp_etd", {
              id: etudiant_id,
              password: pass,
              email: email,
            })
            if (pathFile != "") {
              const formData = new FormData()
              formData.append("document", file)
              formData.append("id", etudiant_id)
              const resA = APIS.post(
                "etudiant/import_photo",
                formData,
                options
              ).then(resA => {
                props.history.push("/dashboard")
              })
            } else {
              props.history.push("/dashboard")
            }
          } else if (res.data.errNum === 300) {
            toast.error("⛔ CIN déja existe !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 301) {
            toast.error("⛔ E-mail déja existe !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 302) {
            toast.error("⛔ CIN invalide !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 500) {
            toast.error("⛔ E-mail invalide !!", {
              containerId: "A",
            })
          }
        })
      }
    } else {
      toast.error("⛔ Spécialité obligatoire !!", {
        containerId: "A",
      })
    }
  }

  const impr = () => {
    window.open(
      "https://etudiant.isggb.com/Impression?cin=" + etudiant_id
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card className="overflow-hidden">
                  <div>
                    <CardBody className="pt-4">
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Informations générales :
                      </CardTitle>
                      <AvForm className="form-horizontal">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nom :
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="Entrer Nom"
                                type="text"
                                errorMessage="* Nom et Prénom obligatoire"
                                className="form-control "
                                value={nom}
                                validate={{ required: { value: true } }}
                                onChange={e => setNom(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الإسم
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="nomar"
                                placeholder="أدخل الاسم "
                                type="text"
                                errorMessage="*الاسم إجباري"
                                className="form-control rtl"
                                value={nomAr}
                                validate={{ required: { value: true } }}
                                onChange={e => setNomAr(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Prénom :
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="prenom"
                                placeholder="Entrer Prénom"
                                type="text"
                                errorMessage="* Nom et Prénom obligatoire"
                                className="form-control "
                                value={prenom}
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenom(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : اللقب
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="prenomar"
                                placeholder="أدخل اللقب "
                                type="text"
                                errorMessage="*اللقب إجباري"
                                className="form-control rtl"
                                value={prenomAr}
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenomAr(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Prénom Père :
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="prenompere"
                                placeholder="Entrer Prénom du Père"
                                type="text"
                                errorMessage="* nom obligatoire"
                                className="form-control"
                                value={prenomPere}
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenomPere(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : اسم الاب
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="prenomperear"
                                placeholder="أدخل الاسم الأول للأب"
                                type="text"
                                errorMessage="*اسم الأب إجباري"
                                className="form-control rtl"
                                value={prenomPereAr}
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenomPereAr(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="d-block mb-3">Sexe :</Label>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline1"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  checked={gender === 1}
                                  onClick={() => setGender(1)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline1"
                                >
                                  Masculin
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline2"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  checked={gender === 2}
                                  onClick={() => setGender(2)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline2"
                                >
                                  Feminin
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Date de naissance :{" "}
                              </Label>
                              <Col md={12} className="pr-0">
                                <DatePicker
                                  className="form-control ddate"
                                  selected={date}
                                  onChange={setDate}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </Col>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gouvernorat de naissance :
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="Entrer Gouvernorat de naissance"
                                type="text"
                                className="form-control"
                                value={gouvernoratNaiss}
                                onChange={e =>
                                  setGouvernoratNaiss(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الولاية
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="أدخل الولاية"
                                type="text"
                                className="form-control rtl"
                                value={gouvernoratNaissAr}
                                onChange={e =>
                                  setGouvernoratNaissAr(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nationalité :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <Select
                              options={options}
                              isSearchable={true}
                              value={selectNationnalite}
                              onChange={e => setSelectNationnalite(e)}
                            />
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الجنسية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                N° CIN ou N° Passeport pour les étrangers :
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <AvField
                              name="numero"
                              placeholder="Entrer identifiant"
                              type="number"
                              className="form-control"
                              value={numero}
                              onChange={target => setNumero(target.value)}
                            />
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : رقم بطاقة التعريف الوطنية
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Etat Civil :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10 center">
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline5"
                              >
                                Célibataire
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline5"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 1}
                                onChange={e => setEtatCivil(1)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline5"
                              >
                                أعزب
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline6"
                              >
                                Marié(e)
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline6"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 2}
                                onChange={e => setEtatCivil(2)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline6"
                              >
                                متزوج
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline7"
                              >
                                Divorcé(e)
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline7"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 3}
                                onChange={e => setEtatCivil(3)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline7"
                              >
                                مطلق
                              </Label>
                            </div>
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الحالة المدنية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Photo :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <div className="mb-3">
                              <Input
                                onChange={e => addFile(e)}
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الصورة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Adresse :
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Rue :{" "}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer rue"
                              type="text"
                              value={rue}
                              onChange={e => setRue(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : النهج{" "}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Code postal :{" "}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer code postal"
                              type="number"
                              value={codePostal}
                              onChange={e => setCodePostal(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الترقيم البريدي{" "}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gouvernorat :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer gouvernorat"
                              type="text"
                              value={gouvernorat}
                              onChange={e => setGouvernorat(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الولاية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Délégation :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer délégation"
                              type="text"
                              value={delegation}
                              onChange={e => setDelegation(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : المعتمدية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Téléphone :{" "}
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <AvField
                              name="tel"
                              placeholder="Entrer téléphone"
                              type="number"
                              errorMessage="* Téléphone obligatoire"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                              value={tel}
                              onChange={e => setTel(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الهاتف
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Baccalauréat
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Année du bac :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer année du bac"
                              type="text"
                              value={anneeBac}
                              onChange={e => setAnneeBac(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : سنة الباكالوريا
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Section :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer section"
                              type="text"
                              value={section}
                              onChange={e => setSection(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الشعبة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Mention :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer mention"
                              type="text"
                              value={mention}
                              onChange={e => setMention(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : ملاحظة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Session :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer session"
                              type="text"
                              value={session}
                              onChange={e => setSession(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الدورة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Pays d'obtention :"}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer pay d'obtention"
                              type="text"
                              value={payObtention}
                              onChange={e => setPayObtention(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : البلد
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Diplôme
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"1ère inscription à L'établissement :"}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer 1ère inscription à L'établissement"
                              type="text"
                              value={premiereIns}
                              onChange={e => setPremiereIns(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : اول سنة تسجيل بالمؤسسة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Cycle :{" "}
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Select
                                options={cycle}
                                isSearchable={true}
                                value={selectCycle}
                                onChange={e => getNiveau(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Niveau :{" "}
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Select
                                options={niveau}
                                isSearchable={true}
                                value={selectNiveau}
                                onChange={e => getSpecialite(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Spécialité :
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Select
                                options={specialite}
                                isSearchable={true}
                                value={selectSpecialite}
                                onChange={e => setSelectSpecialite(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Mise a jour password :
                        </CardTitle>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>
                                E-mail
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <AvField
                                name="email"
                                placeholder="Entrer un email valide"
                                type="email"
                                value={email}
                                errorMessage="* Email obligatoire"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={e => setEmail(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>Password actuel</Label>
                              <Input
                                lg="3"
                                className="form-control"
                                type="password"
                                onBlur={e => passVerif(e.target.value)}
                              />
                              {errPass == 0 ? (
                                <small className="form-text text-danger">
                                  {"Password erroné"}
                                </small>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>Nouveau password</Label>
                              <AvField
                                name="password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>Verification password</Label>
                              <AvField
                                name="password1"
                                type="password"
                                value={verifPassword}
                                onChange={e => setVerifPassword(e.target.value)}
                                errorMessage="Verifier le password"
                                validate={{
                                  match: { value: "password" },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              id="Confirmer"
                              name="Confirmer"
                              type="submit"
                              color="primary"
                              className="btn btn-primary mb-2 me-2"
                              onClick={edit}
                            >
                              Confirmer
                            </Button>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours...
            </h4>
          </div>
        )}
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)

UserProfile.propTypes = {
  history: PropTypes.object,
}
