import React, {Component} from "react"
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap"
import {withRouter} from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import {withTranslation} from "react-i18next"
import API from "../../apis"
import pdf from "../../assets/images/pdfDocument.png"
import FileDownload from "js-file-download"

class DemandsGridsEmploi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cin: "",
            nom: "",
            prenom: "",
        }
    }


    componentDidMount() {
        if (localStorage.getItem("userAuthEtudiant")) {
            var userAuthEtudiant = JSON.parse(
                localStorage.getItem("userAuthEtudiant")
            )
            var etudiant_id = userAuthEtudiant.user.id_etudiant

        }

        const res = API.post("etudiant/get_by_id", {
            id: etudiant_id,
        }).then(res => {
            this.setState({
                cin: res.data.etudiant.code,
                nom: res.data.etudiant.firstName,
                prenom: res.data.etudiant.lastName,
            })
        })
    }

    download = async () => {
        if (localStorage.getItem("userAuthEtudiant")) {
            var userAuthEtudiant = JSON.parse(
                localStorage.getItem("userAuthEtudiant")
            )
            var annee = userAuthEtudiant.user.annee
            var cin = userAuthEtudiant.user.cin
            var name = userAuthEtudiant.user.name
            var etudiant_id = userAuthEtudiant.user.id_etudiant
            var annee_id = userAuthEtudiant.user.annee_id
        }
        const res = await API.get(`impression_group_shedule_etudiant/${etudiant_id}/1/${annee_id}`, {responseType: "blob"}
        ).then(res => {
            FileDownload(res.data, "emploi du temps.pdf")
        })
    }
    download2 = async () => {
        if (localStorage.getItem("userAuthEtudiant")) {
            var userAuthEtudiant = JSON.parse(
                localStorage.getItem("userAuthEtudiant")
            )
            var name = userAuthEtudiant.user.name
            var etudiant_id = userAuthEtudiant.user.id_etudiant
            var annee_id = userAuthEtudiant.user.annee_id
        }
        const res = await API.get(`impression_group_shedule_etudiant/${etudiant_id}/2/${annee_id}`,
            {responseType: "blob"}
        ).then(res => {
            FileDownload(res.data, "emploi du temps.pdf")
        })
    }


    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <Card>
                        <CardBody>
                            <CardTitle style={{color: "#556ee6"}} className="h4">
                                Télécharger vos emplois du temps
                            </CardTitle>
                            <Row>
                                <Row className="justify-content-center">
                                    <Col xl={11}>
                                        <div>
                                            <Row>
                                                <Col sm={2}>
                                                    <Card className=" border shadow-none">
                                                        <a
                                                            onClick={() => this.download()}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <div>
                                                                <i className="fas fa-file-pdf" style={{fontSize:"250px",color:"red"}}/>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="justify-content-center pt-2"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <p style={{fontWeight: "bold"}}>Emploi Semestre
                                                                        1</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Card>
                                                </Col>
                                                <Col sm={2}>
                                                    <Card className=" border shadow-none">
                                                        <a
                                                            onClick={() => this.download2()}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <div>
                                                                <i className="fas fa-file-pdf"
                                                                   style={{fontSize: "250px", color: "red"}}/>
                                                                {/*<img src={pdf} alt="" className="img-thumbnail"/>*/}
                                                            </div>
                                                            <div>
                                                            <div
                                                                    className="justify-content-center pt-2"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <p style={{fontWeight: "bold"}}>Emploi Semestre
                                                                        2</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </React.Fragment>
        )
    }
}

DemandsGridsEmploi.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
}
export default withRouter(withTranslation()(DemandsGridsEmploi))
