import React, { Component } from "react"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink, CardTitle,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import DocumentsGrid from "./DocumentsGridEmploi"
import DocumentsGridEmploi from "./DocumentsGridEmploi";

class DocumentsEmploi extends Component {
  constructor(props) {
    super(props)
    this.state = { section: 1 }
  }

  renderBlock = section => {
    if (section === 1) {
      return <DocumentsGridEmploi />
    }
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>{this.props.t("Emploi du temps")} | Cresus </title>
          </MetaTags>
          <Container fluid>

            <Card>
              <CardBody>
                <Nav className="icon-tab nav-justified">
                  <NavItem>
                    <div>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                              style={{cursor: "pointer"}}
                              className={classnames({
                                active: this.state.section === 1,
                              })}
                              onClick={() => {
                                this.setState({section: 1})
                              }}
                          >
                            {this.props.t("Résultat")}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <Card>
                        <CardBody>{this.renderBlock(this.state.section)}</CardBody>
                      </Card>
                    </div>
                  </NavItem>
                </Nav>
                {/*{renderBlock(section)}*/}
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

DocumentsEmploi.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}
export default withRouter(withTranslation()(DocumentsEmploi))
